import React from 'react';
import Layout from '../components/layout/Layout';
import ModalWindow from '../components/ModalWindow'
import "../style/styles.css"

const Faucet = () => (
  <Layout>
    <div className="lg:px-10">
      <div className="w-full h-full px-4 md:px-32 py-6 mt-12 mx-auto flex justify-center items-center">
        <div className="lg:mb-0 text-2xl md:text-4xl font-bold text-center">
          <div className="">
            <div className='w-full '>
              <ModalWindow xmp="20" type="other" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Faucet;